import { render, staticRenderFns } from "./up_pic2.vue?vue&type=template&id=e84025c6&scoped=true&"
import script from "./up_pic2.vue?vue&type=script&lang=js&"
export * from "./up_pic2.vue?vue&type=script&lang=js&"
import style0 from "./up_pic2.vue?vue&type=style&index=0&id=e84025c6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e84025c6",
  null
  
)

export default component.exports