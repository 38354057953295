<template>
  <div class="up_box">
    <!-- 遮罩层 -->
    <el-upload
      class="upload-demo"
      list-type="picture-card"
      name
      action=""
      :auto-upload="false"
      :show-file-list="false"
      :on-change="changeUpload"
    >
      <i slot="default" class="el-icon-plus" v-if="!(url || coverImg)"></i>
      <img :src="url || coverImg" alt="" v-if="url || coverImg" />
    </el-upload>

    <!-- vueCropper 剪裁图片实现-->
    <el-dialog
      title="图片剪裁"
      :visible.sync="dialogVisible"
      append-to-body
      class="cropperImg_box"
      width="40%"
    >
      <div class="cropper-content">
        <div class="cropper" style="text-align: center">
          <vueCropper
            ref="cropper"
            :img="option.img"
            :outputSize="option.size"
            :outputType="option.outputType"
            :info="true"
            :full="option.full"
            :canMove="option.canMove"
            :canMoveBox="option.canMoveBox"
            :original="option.original"
            :autoCrop="option.autoCrop"
            :fixedBox="option.fixedBox"
            :fixed="option.fixed"
            :fixedNumber="option.fixedNumber"
            :centerBox="option.centerBox"
            :maxImgSize="option.maxImgSize"
            :enlarge="option.enlarge"
            :infoTrue="option.infoTrue"
          ></vueCropper>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <el-button @click="finish" :loading="loading" size="small"
          >确认</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { upload_img } from "@/https/api.js";
export default {
  name: "upPic",
  props: {
    value: [String, Array],
    up_type: {
      type: String,
      default: "bookCover",
    },
    coverImg: {
      type: String,
    },
  },
  data() {
    return {
      url: "",
      dialogVisible: false,
      // 裁剪组件的基础配置option
      option: {
        img: "", // 裁剪图片的地址
        info: true, // 裁剪框的大小信息
        outputSize: 1, // 裁剪生成图片的质量
        outputType: "jpeg", // 裁剪生成图片的格式
        canScale: false, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        // autoCropWidth: 270, // 默认生成截图框宽度
        // autoCropHeight: 380, // 默认生成截图框高度
        fixedBox: false, // 固定截图框大小 不允许改变
        fixed: true, // 是否开启截图框宽高固定比例
        fixedNumber: [27, 38], // 截图框的宽高比例
        full: false, // 是否输出原图比例的截图
        canMoveBox: true, // 截图框能否拖动
        original: false, // 上传图片按照原始比例渲染
        centerBox: true, // 截图框是否被限制在图片里面
        maxImgSize: 3000, //限制图片最大宽度和高度
        enlarge: 1, //图片根据截图框输出比例倍数
        infoTrue: false, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
      },
      picsList: [], //页面显示的数组
      // 防止重复提交
      loading: false,
    };
  },
  methods: {
    // 上传按钮   限制图片大小
    changeUpload(file) {
      var _this = this;
      let big_bag = file.size < 1024 * 1024 * 2 ? true : false;
      let type_1 = file.raw.type === "image/png" ? true : false;
      let type_2 = file.raw.type === "image/jpeg" ? true : false;
      if (!big_bag) {
        this.$message.error("上传文件大小不能超过 2MB!");
        return false;
      }
      if (type_1 || type_2) {
        var reader = new FileReader();
        // 图片文件转换为base64
        reader.readAsDataURL(file.raw);
        reader.onload = function (e) {
          _this.option.img = e.currentTarget.result;
        };
        // 上传成功后将图片地址赋值给裁剪框显示图片
        this.$nextTick(() => {
          this.dialogVisible = true;
        });
      } else {
        this.$message.warning("文件格式不是png/jpeg格式");
      }
    },

    // 点击裁剪，这一步是可以拿到处理后的地址
    finish() {
      var that = this;
      this.$refs.cropper.getCropBlob((data) => {
        var reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onload = function (e) {
          let url = this.result.split(",")[1];
          let props = {
            type: that.up_type,
            file: url,
          };
          that.loading = true;
          upload_img(props).then((res) => {
            if (res.code === 0) {
              that.url = res.data;
              that.$emit("input", res.data);
              that.url = res.data;
            }
            that.loading = false;
            that.dialogVisible = false;
          });
        };
      });
    },
  },
};
</script>

<style lang='scss' scoped>
* {
  padding: 0;
  margin: 0;
}
.up_box {
  width: 100%;
  height: 100%;
}
.el-upload {
  width: 100%;
  height: 100%;
}
/deep/ .upload-demo {
  width: 100%;
  height: 100%;
  img {
    width: 100%;
    height: 100%;
  }
}
/deep/ .el-upload--picture-card {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
// 截图
.cropper-content {
  .cropper {
    width: auto;
    height: 400px;
  }
}

// .cropperImg_box {
//   width: 40%;
// }
</style>
