<template>
  <div class="new_workss">
    <p class="show_set_title">
      <span class="iconfont icon-back close_set" @click="close_set"></span>
      <span>新建作品</span>
    </p>
    <div class="step_label">
      <el-steps
        :space="200"
        :active="active"
        finish-status="success"
        :align-center="true"
      >
        <el-step title="选择类型"></el-step>
        <el-step title="完善信息"></el-step>
        <el-step title="创建成功"></el-step>
      </el-steps>
    </div>
    <div class="step_one" v-if="step_num === 1">
      <div class="step_box step_box_first">
        <span class="step_title">目标读者</span>
        <div class="step_choose">
          <el-radio
            v-model="radio"
            class="dan_choose"
            :label="item.id"
            v-for="(item, index) in channel_arr"
            :key="index"
            >{{ item.name }}
          </el-radio>
        </div>
      </div>
      <div class="step_next">
        <el-button type="primary" @click="go_next(1)">下一步</el-button>
      </div>
    </div>
    <div class="step_two" v-if="step_num === 2">
      <div class="step_box">
        <el-form ref="form" :model="form" :rules="rules" label-width="80px">
          <el-form-item label="作品名称" prop="name">
            <el-input v-model="form.name" style="width: 200px"></el-input>
          </el-form-item>
          <el-form-item label="作品类型" prop="value">
            <el-select
              class="label_selc"
              v-model="form.value"
              :multiple="false"
              placeholder="请选择"
            >
              <el-option
                v-for="(item, index) in options"
                :key="index"
                :label="item.name"
                :value="item.id"
              >
                <!-- :value="item.value" -->
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="参加活动" prop="activityValue">
            <el-select
              class="label_selc"
              v-model="form.activityValue"
              :multiple="false"
              placeholder="请选择"
            >
              <el-option
                v-for="(item, index) in activity"
                :key="index"
                :label="item.name"
                :value="item.id"
              >
                <!-- :value="item.value" -->
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="关键词" prop="keywordValue">
            <el-select
              v-model="form.keywordValue"
              :multiple="true"
              placeholder="请选择"
            >
              <el-option
                v-for="(item, index) in keyword"
                :key="index"
                :label="item.name"
                :value="item.id"
              >
                <!-- :value="item.value" -->
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="作品介绍" prop="text">
            <div flex align-end>
              <textarea
                class="intro_text"
                v-model="form.text"
                key_textarea
                maxlength="220"
              ></textarea>
              <p class="intro_p">
                <span>{{ form.text.length }}字</span>
                <span>不超过220个字</span>
              </p>
            </div>
          </el-form-item>
          <el-form-item label="作品封面" prop="cover_image">
            <div class="image_box">
              <upPic v-model="form.cover_image" :up_type="'system'"></upPic>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div class="step_next">
        <el-button type="primary" @click="create_books(3, 'form')"
          >创建作品
        </el-button>
      </div>
    </div>
    <div class="step_three" v-if="step_num === 3">
      <div class="success_word">
        <h3>作品创建成功</h3>
        <span>开启你的创作之旅吧~</span>
      </div>
      <div class="step_next">
        <el-button type="primary" @click="go_work">去创作</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import upPic from "@/components/input/up_pic2";
import { AuthorcUp, getsexlabels, getAllLabel } from "@/https/mine_center.js";

export default {
  name: "",
  components: {
    upPic,
  },
  data() {
    return {
      active: 0,
      radio: "",
      step_num: 1,
      options: [{ label: "玄幻", value: 1 }],
      form: {
        name: "",
        value: "",
        activityValue: "",
        keywordValue: "",
        text: "",
        cover_image: "",
      },
      rules: {
        name: [
          { required: true, message: "作品名称不能为空", trigger: "blur" },
        ],
        value: [
          { required: true, message: "作品标签不能为空", trigger: "blur" },
        ],
        activityValue: [
          { required: true, message: "参加活动不能为空", trigger: "blur" },
        ],
        keywordValue: [
          { required: true, message: "关键字不能为空", trigger: "blur" },
          { required: true, validator: this.len_vaule, trigger: "change" },
        ],
        text: [
          { required: true, message: "作品简介不能为空", trigger: "blur" },
        ],
        cover_image: [
          { required: true, message: "作品封面不能为空", trigger: "change" },
        ],
      },
      activity: [], //活动分类
      keyword: [], //关键字分类
      labels: [],
      cur_book_id: "",
      channel_arr: [],
    };
  },
  created() {
    this.get_options();
    this.avoid_channel();
  },
  methods: {
    // 作品标签验证
    len_vaule(rule, value, callback) {
      console.log(value);
      if (value && value.length > 5) {
        callback(new Error("作品标签最多添加5个"));
      }
      callback();
    },
    // 下一步
    next(num) {
      if (this.step_num) {
        this.step_num += 1;
      }
      this.active = num;
    },
    // 关闭新建
    close_set() {
      this.$router.push({
        path: "/works_manage",
      });
    },
    // 去写作中心
    go_work() {
      const { href } = this.$router.resolve({
        path: "write_center",
        query: {
          books_id: this.cur_book_id,
        },
      });
      window.open(href, "_blank");
    },
    // 下一步
    go_next(num) {
      // if(!this.radio){
      //   this.$message.warning("请选择频道")
      // }else{
      //   this.next(num)
      // }
      this.next(num);
    },
    // 获取所有频道标签
    avoid_channel() {
      getsexlabels().then((res) => {
        if (res.code === 0) {
          this.channel_arr = res.data;
        }
      });
    },
    // 获取所有标签
    get_options() {
      getAllLabel().then((res) => {
        if (res.code === 0) {
          const data = res.data;
          const { category = [], activity = [], keyword = [] } = data || {};
          this.options = category;
          this.activity = activity;
          this.keyword = keyword;
        }
      });
    },
    // 创建作品提交
    create_books(index, formName) {
      let label = [];
      console.log(this.form.value);
      label = [
        this.form.value,
        this.radio,
        this.form.activityValue,
        ...(this.form.keywordValue || []),
      ];
      let params = {
        table: 2,
        bookid: sessionStorage.getItem("person_id"),
        name: this.form.name,
        intro: this.form.text,
        cover_image: this.form.cover_image,
        labels: label.join(","),
      };
      this.$refs[formName].validate((valid) => {
        if (valid) {
          AuthorcUp(params).then((res) => {
            if (res.code === 0) {
              this.cur_book_id = res.data;
              this.$message({
                message: "创建成功",
                type: "success",
              });
              this.next(index);
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../../utils/tool.scss";
// @include flex-center();
.new_workss {
  .show_set_title {
    line-height: 30px;

    .close_set {
      cursor: pointer;
      font-size: 18px;
      margin-right: 10px;
    }

    .close_set:hover {
      color: #409eff;
    }
  }
}

.step_label {
  padding: 10px 0;

  /deep/ .el-steps {
    justify-content: center;
  }
}

.step_box {
  padding: 10px 130px;
  @include flex-column();

  .step_choose {
    @include flex-column();
    margin: 0 auto;
    line-height: 30px;

    span {
      margin-bottom: 30px;
      text-indent: 2em;
    }
  }

  .step_title {
    text-align: left;
    font-size: 16px;
  }

  .intro_text {
    border: 1px solid #e4e4e4;
    margin-right: 10px;
    padding: 10px;
    border-radius: 4px;
    width: 400px;
    height: 150px;
  }

  .intro_text:focus {
    border: 1px solid #409eff;
  }

  .intro_p {
    color: #999;
    font-size: 12px;

    & span:nth-of-type(1) {
      padding: 8px;
    }
  }
}

.step_next {
  @include flex-center();
  padding: 15px 0;

  /deep/ .el-button {
    padding: 8px 15px;
  }
}

.image_box {
  box-shadow: 2px 2px 6px #bbbbbb;
  width: 135px;
  height: 190px;
  border-radius: 2px;
  overflow: hidden;
}

.step_three {
  .success_word {
    @include flex-column-center();

    h3 {
      padding: 36px;
    }

    span {
      padding: 30px;
    }
  }
}

/deep/ .el-select {
  width: 400px !important;
}

/deep/ .is-process {
  color: #666666 !important;
  border-color: #666666 !important;

  /deep/ .el-step__line-inner {
    color: #666666 !important;
  }

  /deep/ .el-step__icon {
    color: #666666 !important;
    border-color: #666666 !important;
  }
}

/deep/ .is-success {
  color: #409eff !important;
  border-color: #409eff !important;

  /deep/ .el-step__line-inner {
    color: #409eff !important;
  }

  /deep/ .el-step__icon {
    color: #409eff !important;
    border-color: #409eff !important;
  }
}

.step_box_first {
  .step_choose {
    margin: 0 !important;
  }
}

.dan_choose {
  line-height: 24px;
  padding: 20px 0 0 70px;
}

.label_selc {
  /deep/ .el-input__inner {
    height: 32px !important;
  }
}
/deep/ .el-input .el-input__inner {
  height: 32px;
}
</style>
